body {
    background-color: rgb(10, 3, 27);

}

.homebody {
    background-image: url('../assets/images/bgimage.png');
    background-repeat: no-repeat;
    /* background-size:100% 100%; */
    background-size: cover;
    background-color: rgb(10, 3, 27);
}

.header {
    display: flex;
    justify-content: space-between;
}

.header_logo {
    /* flex-grow: 0.2; */
    width: 300px;
    /* margin-right: 30px; */
}

.logomark {
    width: 170px;
    height: 70px;
    margin-left: 80px;
    cursor: pointer;
    /* margin-right: 120px; */
}

.multi-chain {
    /* margin-top: 32px; */
    color: rgb(90, 160, 248);
    font-weight: bold;
    font-family: futura;
    margin-right: 30px;
}

.multi-chain-sonic {
    color: rgb(18, 1, 245);
    font-weight: bold;
    font-family: futura;
    margin-right: 30px;
}

.multi-sui {
    color: rgb(99, 160, 248);
    font-weight: bold;
    font-family: futura;
    font-size: medium;
    margin-left: 5px;
}

.sui_logo {
    height: 18px;
    /* margin-top:5px; */
}

.sui_logo_menu {
    height: 20px;
    margin-left: 2px;
}

.selected_sui {
    display: flex;
    align-items: center;
}

.multi-sonic {
    color: rgb(18, 1, 245);
    font-weight: bold;
    font-family: futura;
    font-size: medium;
}

.multi-ton {
    color: rgb(67, 150, 227);
    font-weight: bold;
    font-family: futura;
    font-size: medium;
}

.multi-sonicx{
    color: rgb(18, 1, 245);
    font-weight: bold;
    font-family: futura;
    font-size: medium;
}

.sonic_menu {
    height: 20px;
    margin-right: 5px;
}

.sonic_logo {
    height: 25px;
    margin-right: 5px;
}

.chain_menu_line {
    display: flex;
    align-items: center;
}

.ton_menu {
    height: 20px;
    margin-right: 5px;
}

.sonicx_menu{
    height: 20px;
    margin-right: 5px;
}

.navigation_bt {
    display: flex;
    /* justify-content: space-around; */
    /* flex-direction: row; */
    align-items: center;
    /* align-content: flex-start; */
    /* justify-self: flex-end; */
    /* justify-content:space-around; */
    margin-right: 30px;
    margin-top: 20px;
    font-family: futura;
}

.nav {
    color: grey;
    font-weight: 700;
    margin-right: 30px;
    margin-left: 40px;
    cursor: pointer;
    /* margin-top: 50px; */
}

.nav.selected {
    /* background-color: #4D8CF3; */
    color: rgb(115, 96, 191);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5px;
}

.nav.selected::after {
    content: "";
    border-radius: 50%;
    height: 5px;
    width: 5px;
    /* margin-left: 30px; */
    text-align: center;
    background-color: rgb(115, 96, 191);
}

.coming_soon {
    color: white;
    font-weight: bold;
    font-size: large;
    text-align: center;
}

.wallet_bt {
    border-radius: 100px;
    background: linear-gradient(to right, rgb(147, 129, 227), rgb(59, 64, 203));
    color: white;
    border-color: rgba(252, 254, 255, 0);
    border-width: 0px;
    width: 160px;
    height: 40px;
    font-weight: 700;
    cursor: pointer;
    font-family: futura;
    /* justify-content: end;
    margin-top: 40px; */
}

.wallet_bt_hidden {
    display: none;
}

.sonic_wallet {}

.wallet_address {}

.wallet_address_hidden {
    display: none;
}

.connect_wallet {
    color: #ffffff;
    font-size: large;
    text-align: center;
    font-weight: bold;
    font-family: futura;
}


.homebase {
    color: white;
}

.title_season {
    text-align: center;
    font-weight: bolder;
    font-size: xx-large;
    margin-top: 40px;
    font-family: futura;
}

.title_prize {
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    margin-top: 15px;
    font-family: futura;

}

.prize_pool {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.prize_logo_ring {
    height: 120px;
    /* margin-right: 100px */
}

.prize_logo {
    height: 150px;
    /* width: ; */
    margin-top: 5px;
}

.prize_logo_sonic {
    height: 120px;
    /* width: ; */
    margin-top: 5px;
}

@font-face {
    font-family: lcd;
    src: url('../assets/font/lcd.ttf');
}

.prize_num {
    font-weight: bolder;
    font-size: 150px;
    font-family: lcd;
    color: rgb(241, 220, 110);
    margin-right: 50px;
    /* margin-left: 10px; */
    /* background-image: linear-gradient(180deg, rgb(232, 227, 173), rgb(247, 204, 32)); */
    /* color: transparent; */
    /* background-clip: text; */
    /* border: #9d8526; */
    -webkit-text-stroke: 3px rgb(153, 100, 34);
    text-shadow: 0 0 30px #aea997, 0 0 30px #aea997, 0 0 30px #aea997, 0 0 30px #aea997;
    /* height: 100px; */
}

.prize_num_sonic {
    font-weight: bolder;
    font-size: 150px;
    font-family: lcd;
    color: rgb(240, 179, 88);
    margin-right: 20px;
    -webkit-text-stroke: 3px rgb(254, 254, 254);
    text-shadow: 0 0 10px #ae9c82, 0 0 20px #ae9c82, 0 0 30px #ae9c82, 0 0 30px #ae9c82;
}

.hp_tx_time {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.tx_hp {
    margin-left: 30px;
}

.tx_title {
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    margin-top: 10px;
    font-family: futura;
}

.tx_num {
    text-align: center;
    font-weight: bolder;
    font-size: 70px;
    font-family: lcd;
    color: #ffffff;
    -webkit-text-stroke: 1px rgba(254, 254, 254, 0.69);
    text-shadow: 0 0 10px #5555ebb5, 0 0 20px #5555ebb5, 0 0 30px #5555ebb5, 0 0 30px #5555ebb5;
}


.rules {
    color: white;
}

.countdown_hp {
    margin-right: 30px;
}

.title_time {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    margin-top: 10px;
    font-family: futura;

}

.count_down {
    /* width: 300px; */
    text-align: center;
    font-size: 70px;
    text-shadow: 0 0 10px #b531bcea, 0 0 20px #b531bcea, 0 0 30px #b531bcea, 0 0 40px #b531bcea;
    /* font-weight: bold; */
    /* font-family: 'Times New Roman', Times, serif; */
    /* font-family: futura; */
    font-family: lcd;

    display: flex;
    justify-content: center;
    /* margin-top: 10px; */
}

.hours_h {
    /* width: 100px; */
}

.minute_h {
    width: 100px;
}

.seconds {
    width: 100px;
}

.colon_h {
    width: 20px;
}


/* .team{
    position: absolute;
} */

.bluev {
    position: absolute;
    left: 31%;
    top: 280px;
}

.yellows {
    position: absolute;
    right: 390px;
    top: 280px;
}

.pillblue {
    position: absolute;
    top: 480px;
    left: 790px;
}

.pillred {
    position: absolute;
    top: 470px;
    right: 780px;
}

.v {
    position: absolute;
    left: 510px;
    top: 320px;
    z-index: 1;
    height: 400px;
}

.s {
    position: absolute;
    right: 520px;
    top: 340px;
    z-index: 1;
    height: 400px;
}

.team_frame {
    background: url('../assets/images/pills.png') no-repeat center center;
    /* background-repeat: no-repeat; */
    background-size: 450px;
    /* background-size: cover; */
    /* z-index: 1; */
    height: 170px;
    /* text-align: bottom; */
    display: flex;
    justify-content: center;
    font-family: futura;
    font-weight: bold;
    margin-top: 10px;
}

.team_players {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* .pills{

    display: block;
    margin: 0 auto;
    height: 150px;
    width: 500;
} */

.key_in_pot {
    /* position: absolute; */
    display: flex;
    /* justify-content: center; */
    text-align: center;
    /* align-content: flex-end; */
    /* margin-top: 50px; */
    /* text-align: center; */
}

.chain_in_pot {
    /* position: absolute; */
    margin-right: 80px;
    margin-top: 120px;
    z-index: 2;
}

.meme_in_pot {
    /* position: absolute; */
    margin-left: 40px;
    margin-top: 120px;
    z-index: 2;
}

.rule_parent {
    margin-top: 10px;
    display: flex;
    justify-content: center;
}

.title_rule {
    color: rgb(255, 230, 0);
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
    z-index: 2;
    font-family: futura;
}

.claim_button {
    display: none;
}

.rules_title {
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: large;
}

.rules_body {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.rules_line {
    display: flex;
    flex-direction: row;
    margin-left: 30px;
    margin-right: 20px;
    margin-top: 40px;
    /* justify-content: space-between; */
}

.rules_num {
    /* box-sizing: border-box; */

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;

    background: #85B6FF;
    border: 4px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    width: 15px;
    height: 15px;
    color: white;
    font-weight: bold;

    /* position: relative; */
    /* text-align: center; */
    margin-right: 15px;
    margin-top: 5px;

}

.rules_text {
    width: 700px;
    color: white;
    font-size: medium;
    text-align: left;
    font-weight: 500;
    /* line-height: 18px; */
}

.rules_wallet {
    width: 700px;
    color: white;
    font-size: medium;
    font-weight: 500;
    /* display: flex; */
    /* flex-direction: row; */
}

.nightly_wl {
    color: rgb(255, 230, 0);
    font-weight: bold;
}

.rules_battle {
    margin-top: 20px;
    height: 80px;
    width: 250px;
}

.rules_key_mint {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.rules_key {
    height: 280px;
    margin-right: 20px;
}

.rules_nightly {
    height: 490px;
    margin-right: 20px;
}

.rules_milestone {
    height: 380px;
    margin-top: 40px;
}

.rules_mint {
    height: 250px;
    margin-left: 20px;
}

.rules_mint_sonic {
    height: 330px;
    margin-left: 20px;
}

.rules_sonic_img_1 {
    height: 400px;
}

.sonic_rules_last_line {
    margin-top: 50px;
    font-family: futura;
    font-weight: 600;
    margin-bottom: 30px;
}

.rules_arrow {
    height: 20px;
    width: 50px;
}

.rules_games {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.rules_refer {
    margin-top: 40px;
    height: 280px;
    margin-bottom: 50px;
}

.rules_game {
    height: 300px;
    /* width: 200px; */
    margin-left: 40px;
    margin-right: 40px;
}

.rules_table {
    color: white;
    margin-top: 20px;
    border-collapse: collapse;
    font-family: futura;
    /* border-collapse: collapse; */
    /* border-collapse: collapse;
    border: 1px solid #ffffff; */
}

.table_title {
    text-align: left;
    font-weight: bold;
    border: 1px solid #ccc;

}

.table_text {
    text-align: center;
    border-top: 0;
    border-left: 0;
    border: 1px solid #ccc;

}

.table2 {
    margin-left: 20px;
}

.last_row {
    border-bottom: 0;
}

.p {
    font-size: xx-small;
}

.firstsentence {
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: bold;
    margin-top: 15px;
    font-size: medium;
}

.last_line {
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: bold;
    font-size: medium;
}

.view_on_pc {
    display: none;
}

.purchase_container {
    display: flex;
    margin-top: 50px;
    justify-content: center;
    margin-bottom: 30px;
    color: white;
}

.purchase_container_sonic {
    display: flex;
    margin-top: 50px;
    justify-content: center;
    margin-bottom: 30px;
    color: white;
}

.purchase_left {
    width: 25%;
    height: 510px;
    background-color: #170f23dc;
    /* font-family: Georgia, 'Times New Roman', Times, serif; */
    font-family: futura;
    font-weight: 500;
}

.purchase_left_sonic {
    width: 25%;
    height: 450px;
    background-color: #170f23dc;
    /* font-family: Georgia, 'Times New Roman', Times, serif; */
    font-family: futura;
    font-weight: 500;
}

.purchase_middle_1 {
    width: 40%;
    height: 510px;
    margin-left: 20px;
    margin-right: 20px;
    background-color: #170f23dc;
    /* font-family: Georgia, 'Times New Roman', Times, serif; */
    font-family: futura;
    font-weight: 500;
}

.purchase_middle_1_sonic {
    width: 40%;
    height: 450px;
    margin-left: 20px;
    margin-right: 20px;
    background-color: #170f23dc;
    /* font-family: Georgia, 'Times New Roman', Times, serif; */
    font-family: futura;
    font-weight: 500;
}

.purchase_middle_1_hidden {
    display: none;
}

.purchase_middle_2 {
    width: 40%;
    height: 510px;
    margin-left: 20px;
    margin-right: 20px;
    background-color: #170f23dc;
    /* font-family: Georgia, 'Times New Roman', Times, serif; */
    font-family: futura;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.claim_rings {
    margin-right: 20px;
    font-size: medium;
    font-weight: 600;
    border-radius: 100px;
    height: 30px;
    width: 120px;
    /* background-color: rgb(147, 129, 227); */
    background: linear-gradient(to right, rgb(147, 129, 227), rgb(59, 64, 203));
    color: white;
    border-color: rgba(255, 255, 255, 0);
    /* outline-style: solid; */
    outline-color: rgba(255, 255, 255);
    border-width: 0px;
    font-family: futura;
    /* margin-left: 20px; */
    cursor: pointer;

}


.tgbot_bt {
    margin-right: 20px;
    font-size: medium;
    font-weight: 600;
    border-radius: 100px;
    height: 30px;
    width: 160px;
    /* background-color: rgb(147, 129, 227); */
    background: linear-gradient(to right, rgb(147, 129, 227), rgb(59, 64, 203));
    color: white;
    border-color: rgba(255, 255, 255, 0);
    /* outline-style: solid; */
    outline-color: rgba(255, 255, 255);
    border-width: 0px;
    font-family: futura;
    /* margin-left: 20px; */
    cursor: pointer;
}

.purchase_middle_2_hidden {
    display: none;
}

.purchase_right {
    width: 25%;
    height: 510px;
    background-color: #170f23dc;
    /* font-family: Georgia, 'Times New Roman', Times, serif; */
    font-family: futura;
    font-weight: 500;
}

.purchase_right_sonic {
    width: 25%;
    height: 450px;
    background-color: #170f23dc;
    /* font-family: Georgia, 'Times New Roman', Times, serif; */
    font-family: futura;
    font-weight: 500;
}


.purchase_title {
    text-align: center;
    font-weight: bold;
    font-size: x-large;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: futura;
    /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
}

.purchase_title_side_sonic {
    margin-top: 50px;
}

.purchase_title_key {
    text-align: center;
    font-weight: bold;
    font-size: xx-large;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: futura;
    /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
}

.purchase_left_1 {
    font-size: 20px;
    margin-left: 20px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: futura;
}


.purchase_left_1_team {
    margin-top: 70px;
}

.purchase_left_last {
    margin-bottom: 40px;
}

.teamlogo {
    height: 50px;
    margin-right: 100px;
}

.sonic_teamlogo {
    height: 50px;
    margin-right: 40px;
}

.last_line {
    margin-bottom: 50px;
}

.purchase_left_2 {
    font-size: 17px;
    margin-left: 20px;
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.purchase_left_2_sonic_rings {
    margin-right: 20px;
}

.purchase_left_key {
    margin-right: 20px;
    font-size: large;
}

.purchase_left_game {
    margin-right: 20px;
    font-size: large;
}

.purchase_left_2_value {
    margin-right: 65px;
}

.vault_sui_left {
    height: 20px;
    margin-right: 20px;
}

.purchase_left_2_pot {
    margin-left: 115px;
}

.purchase_left_2_gain {
    margin-left: 85px;
}

.purchase_left_2_key {
    margin-left: 115px;
}

.purchase_left_2_game {
    margin-left: 95px;
}

.purchase_middle_yellow {
    text-align: center;
    font-weight: 500;
    font-size: x-large;
    color: gold;
}

.purchase_middle_2_yellow {
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    color: gold;
    margin-top: 30px;
}

.gold{
    color: gold;
}

.purchase_block_up {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;


    font-size: medium;
    font-weight: 500;
    height: 60px;
    width: 90%;
    background-color: rgb(116, 58, 185);
    /* background: linear-gradient(to right, rgb(147, 129, 227), rgb(59, 64, 203)); */
    color: white;
    border-color: rgba(255, 255, 255, 0);
    outline-style: solid;
    outline-color: rgba(255, 255, 255);
    border-width: 1px;

}

.currency {
    font-size: medium;
    font-weight: 600;
    border-radius: 6px;
    margin-left: 15px;


    height: 45px;
    width: 190px;
    background-color: rgb(62, 21, 125);
    /* background: linear-gradient(to right, rgb(147, 129, 227), rgb(59, 64, 203)); */
    /* color: white; */
    /* border-color: rgb(255, 255, 255); */
    outline-style: solid;
    outline-color: rgb(255, 255, 255);
    border-width: 1px;
    font-family: futura;

    /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
}

.cur_blue_sui {
    height: 35px;
    display: block;
    margin: 0 auto;
    margin-top: 5px;
}

.coin_menu_line {
    display: flex;
    align-items: center;
}

.buck_buykey {
    height: 40px;
    margin-left: 30px;
}

.sui_buykey {
    height: 30px;
    margin-left: 40px;
}

.coin_dropdown {
    display: block;
    margin: 0 auto;
}

.coin_text_buck {
    color: #ffffff;
    font-family: futura;
    font-size: 18px;
    font-weight: bold;
    margin-left: 5px;
}

.coin_text_sui {
    color: #ffffff;
    font-family: futura;
    font-size: 20px;
    font-weight: bold;
    margin-left: 8px;
}

.selected_coin_sui {
    /* display: block; */
    margin-left: 70px;
}

.select_cur {
    display: flex;
    justify-content: space-between;
}

.cur_buck {
    height: 37px;
}

.selected_coin_buck {
    margin-top: 3px;
}

.cur_arrow {
    margin-right: 10px;
}

.key_nums {
    font-size: medium;
    font-weight: 600;
    border-radius: 6px;
    margin-right: 15px;

    height: 45px;
    width: 260px;
    background-color: rgb(62, 21, 125);
    /* background: linear-gradient(to right, rgb(147, 129, 227), rgb(59, 64, 203)); */
    color: white;
    border-color: rgb(255, 255, 255);
    outline-style: solid;
    outline-color: rgb(255, 255, 255);
    border-width: 1px;
    /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
    /* font-family: futura; */

    display: flex;
    align-items: center;
    justify-content: space-between;

    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.minus {
    margin-left: 10px;
    font-size: large;
    font-weight: bold;
    cursor: pointer;
}

.keynums {
    margin-left: 50px;
    font-size: x-large;
}

.multi {
    margin-left: 10px;
    font-size: x-large;
}

.keyemoji {
    margin-right: 40px;
    font-size: x-large;
    margin-top: 5px;
}

.add {
    margin-right: 10px;
    font-size: xx-large;
    cursor: pointer;
}

.purchase_block_down {
    font-size: medium;
    font-weight: 500;
    height: 60px;
    width: 90%;
    /* background: linear-gradient(to right, rgb(147, 129, 227), rgb(59, 64, 203)); */
    /* color: white; */
    /* border-color: rgba(255, 255, 255, 0); */
    outline-style: solid;
    outline-color: rgb(255, 255, 255);
    border-width: 1px;
    margin-top: 1.5px;

    display: flex;

    font-family: futura;

    /* font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif; */
    text-align: center;
}

.key_block {
    height: 60px;
    width: 22.5%;
    outline-color: rgb(255, 255, 255);
    outline-width: 1px;
    outline-style: solid;
    text-align: center;
}

.key_add {
    margin-top: 20px;
    font-weight: 600;
    cursor: pointer;
}

.pill_choose {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 60px;
    margin-right: 35px;
    margin-left: 40px;
}

.pill_choose_sonic {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 30px;
    margin-right: 35px;
    margin-left: 40px;
}

.pillblue_2 {
    height: 110px;
    /* margin-right: 50px; */
}

.pillblue_2:hover {
    transform: scale(1.1)
}

.pillblue_2.selected {
    height: 150px;
}

.pillred_2 {
    height: 110px;
}

.pillred_2.selected {
    height: 150px;
}

.pillred_2:hover {
    transform: scale(1.1)
}

.pillblue_selected {
    height: 150px;
}

.pillred_unselected {
    height: 110px;
}

.pillblue_unselected {
    height: 110px;
}

.pillred_selected {
    height: 150px;
}

.team_choose {
    display: flex;
    justify-content: space-around;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 20px;
    font-size: large;
    font-weight: bold;
}

.purchasse_middle_button {
    display: block;
    margin: 0 auto;
    margin-top: 60px;

    font-size: medium;
    font-weight: 600;
    border-radius: 100px;
    height: 30px;
    width: 120px;
    /* background-color: rgb(147, 129, 227); */
    background: linear-gradient(to right, rgb(147, 129, 227), rgb(59, 64, 203));
    color: white;
    border-color: rgba(255, 255, 255, 0);
    /* outline-style: solid; */
    outline-color: rgba(255, 255, 255);
    border-width: 0px;
    font-family: futura;
    margin-left: 20px;
    /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
}

.purchasse_middle_button_go {
    display: block;
    margin: 0 auto;
    margin-top: 60px;

    font-size: medium;
    font-weight: 600;
    border-radius: 100px;
    height: 30px;
    width: 120px;
    /* background-color: rgb(147, 129, 227); */
    background: linear-gradient(to right, rgb(147, 129, 227), rgb(59, 64, 203));
    color: white;
    border-color: rgba(255, 255, 255, 0);
    /* outline-style: solid; */
    outline-color: rgba(255, 255, 255);
    border-width: 0px;
    font-family: futura;
    /* margin-left: 20px; */
}

.purchasse_middle_button_play {
    display: block;
    margin: 0 auto;
    margin-top: 42px;

    font-size: medium;
    font-weight: 600;
    border-radius: 100px;
    height: 30px;
    width: 120px;
    /* background-color: rgb(147, 129, 227); */
    background: linear-gradient(to right, rgb(147, 129, 227), rgb(59, 64, 203));
    color: white;
    border-color: rgba(255, 255, 255, 0);
    /* outline-style: solid; */
    outline-color: rgba(255, 255, 255);
    border-width: 0px;
    font-family: futura;
    /* margin-left: 20px; */
    cursor: pointer;
}

.purchasse_middle_button:hover {
    transform: scale(1.1)
}

.faucet_middle_button {
    display: block;
    margin: 0 auto;
    margin-top: 60px;

    font-size: medium;
    font-weight: 600;
    border-radius: 100px;
    height: 30px;
    width: 120px;
    /* background-color: rgb(147, 129, 227); */
    background: linear-gradient(to right, rgb(147, 129, 227), rgb(59, 64, 203));
    color: white;
    border-color: rgba(255, 255, 255, 0);
    /* outline-style: solid; */
    outline-color: rgba(255, 255, 255);
    border-width: 0px;
    font-family: futura;
    margin-right: 20px;
}

.faucet_play_button {
    display: block;
    margin: 0 auto;
    /* margin-top: 60px; */
    margin-left: 150px;

    font-size: 17px;
    font-weight: 600;
    border-radius: 100px;
    height: 30px;
    width: 120px;
    /* background-color: rgb(147, 129, 227); */
    background: rgb(147, 126, 245);
    color: white;
    /* border-color: rgba(255, 255, 255, 0); */
    /* outline-style: solid; */
    /* outline-color: rgba(255, 255, 255);
    border-width: 0px; */

    border-color: rgb(255, 255, 255);
    border-width: 0px;

    font-family: futura;

    cursor: pointer;
    /* margin-right: 20px; */
}

.faucet_play_button:hover {
    background: rgb(137, 118, 220);
}

.purchase_buttons {
    display: flex;
}

.faucet_middle_button:hover {
    transform: scale(1.1)
}

.teampompt {
    color: white;
    font-weight: 600;
    text-align: center;
    font-size: medium;
    margin-top: 30px;
    font-family: futura;
}

.teampompt_title {
    font-weight: 600;
}

.team_confirm_bt {
    margin-top: 30px;
    display: flex;
    justify-content: space-around;
}

.confirm_bt {
    font-size: medium;
    font-weight: 600;
    border-radius: 100px;
    height: 30px;
    width: 120px;
    background-color: rgb(147, 129, 227);
    /* background: linear-gradient(to right, rgb(147, 129, 227), rgb(59, 64, 203)); */
    color: white;
    border-color: rgba(255, 255, 255, 0);
    outline-style: solid;
    outline-color: rgba(255, 255, 255);
    border-width: 1px;
    font-family: futura;
    cursor: pointer;
}

.confirm_bt:hover {
    background-color: rgb(132, 115, 203);
}

.buykeypompt {
    margin-top: 30px;
    font-size: large;
    font-weight: 600;
    color: white;
    text-align: center;
    font-family: futura;
}

.getkeys_confirm_bt {
    font-size: small;
    margin-top: 30px;
    display: flex;
    justify-content: space-around;
}

.mint_play {
    height: 25px;
    width: 130px;
}

.key_back {
    height: 25px;
    width: 130px;
}

.bukeypompt_text {
    margin-top: 10px;
}

.bukeypompt_emoji {
    font-size: xx-large;
}

.pillblue_3 {
    height: 60px;
}

.pillred_3 {
    height: 60px;
}

.purchase_right_top {
    font-size: 20px;
    /* margin-left: 50px; */
}

.vault {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 20px;
}

.vault_line_1 {
    margin-top: 80px;
}

.vault_sui {
    height: 25px;
    margin-right: 20px;
}

.sonic_vault_value {
    margin-right: 20px;
}

.withdraw_bt {
    display: block;
    margin: 0 auto;
    margin-top: 30px;
    border-radius: 100px;
    font-weight: bold;
    height: 30px;
    width: 120px;
    background: linear-gradient(to right, rgb(147, 129, 227), rgb(59, 64, 203));
    color: white;
    border-color: rgba(255, 255, 255, 0);
    border-width: 0px;
    font-family: futura;
    /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
}

.withdraw_bt:hover {
    transform: scale(1.1);
}

.purchase_right_middle {
    text-align: center;
    margin-top: 30px;
    font-size: 30px;
    font-family: futura;
    /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
    font-weight: bold;
    font-size: x-large;
}

.link_line {
    display: flex;
    justify-content: space-evenly;
    /* align-items: center; */
    margin-top: 20px;
}

.refer_link {
    /* display:flex; */
    /* flex-direction: column; */
    /* justify-content: space-evenly; */
    /* align-items: center; */
    /* margin-left: 50px; */
    font-size: 20px;
}

.copy_link {
    margin-left: 20px;
    border-radius: 100px;
    font-weight: bold;
    height: 30px;
    width: 120px;
    background: rgb(147, 129, 227);
    color: white;
    border-color: rgba(255, 255, 255, 0);
    border-width: 0px;
    font-family: futura;
    /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
}

.copy_link:hover {
    transform: scale(1.1)
}

.buy_link {
    margin-left: 20px;
    border-radius: 100px;
    font-weight: bold;
    height: 30px;
    width: 140px;
    background: rgb(147, 129, 227);
    color: white;
    border-color: rgba(255, 255, 255, 0);
    border-width: 0px;
    font-family: futura;
    /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
}

.buy_link:hover {
    transform: scale(1.1)
}


.media {
    display: flex;
    justify-content: center;
    /* margin-right: 20px; */
    margin-top: 290px;
}

.discord {
    height: 30px;
    width: 30px;
    margin-left: 6px;
    margin-right: 6px;
}

.tele {
    height: 28px;
    width: 28px;
    margin-left: 6px;
    margin-right: 6px;
}

.twi {
    height: 27px;
    width: 27px;
    margin-left: 6px;
    margin-right: 6px;
}

.med {
    height: 28px;
    width: 28px;
    margin-left: 6px;
    margin-right: 6px;
}

.gitbook {
    height: 28px;
    width: 28px;
    margin-left: 6px;
    margin-right: 6px;
}

.discord:hover {
    transform: scale(1.1)
}

.tele:hover {
    transform: scale(1.1)
}

.twi:hover {
    transform: scale(1.1)
}

.med:hover {
    transform: scale(1.1)
}

.gitbook:hover {
    transform: scale(1.1)
}

.bottom {
    height: 22.5px;
}

.lockdown {
    /* margin-left: 70px; */
}

.rebate {
    margin-left: 60px;
}

.refer {
    margin-left: 50px;
}

.totalgain {
    margin-left: 20px;
}


@media screen and (max-width: 800px) {
    .homebody {
        background-color: #0f0e4d;
        background-image: none;
    }

    .header {
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
    }

    .btc_nft {
        display: none;
    }

    .header_logo {
        display: block;
        margin: 0 auto;
    }

    .nav {
        display: none;
        /* color: grey;
        font-weight: 500;
        margin-right: 5px;
        margin-left: 5px;
        cursor: pointer;
        font-size: 15px; */
    }

    .navigation_bt {
        display: none;
        /* display: flex;
        align-items: center;
        margin-top: 20px;
        font-family: futura; */
    }
    .mobile_wallet{

    }

    .multi-chain-sonic {
        display: none;
    }

    .header_logo {
        margin-top: 20px;
        text-align: center;
    }

    .logomark {
        margin-left: 0px;
    }

    .title_season {
        font-size: larger;
    }

    .title_prize {
        font-size: large;
    }

    .prize_pool {
        margin: 20px;
    }

    .prize_logo_sonic {
        height: 50px;
    }

    .prize_num_sonic {
        color: rgb(241, 220, 110);
        font-weight: 400;
        font-size: 60px;
        -webkit-text-stroke: 1px rgba(153, 99, 34, 0.912);
        text-shadow: 0 0 1px #aea997, 0 0 3px #aea997, 0 0 5px #aea997, 0 0 7px #aea997;
        margin-right: 10px;
    }

    .prize_logo_ring {
        height: 50px;
    }

    .countdown_hp {
        margin-right: 0px;
    }

    .wallet_bt_sonic {
        width: 10px;
    }


    .hp_tx_time {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .title_time {
        /* margin-top: 20px; */
        font-size: large;
    }

    .tx_title {
        margin-top: 20px;
        font-size: large;
    }

    .tx_hp {
        display: none;
    }

    .tx_pp {
        display: none;
    }

    .count_down {
        font-size: 40px;
    }

    .tx_num {
        font-size: 40px;
    }

    .faucet_play_button {
        display: none;
    }

    .team_frame {
        background: url('../assets/images/pills.png') no-repeat center center;
        background-size: 300px;
        z-index: 1;
        height: 120px;
        font-weight: 500;
        font-size: small;
    }

    .chain_in_pot {
        /* position: absolute; */
        margin-right: 80px;
        margin-top: 85px;
        z-index: 2;
    }

    .meme_in_pot {
        /* position: absolute; */
        margin-left: 20px;
        margin-top: 85px;
        z-index: 2;
    }

    .title_rule {
        display: none;
    }

    .claim_button {
        /* border-radius: 2.6667rem 2.6667rem 2.6667rem 2.6667rem; */
        display: block !important;
        border-radius: 100px;
        height: 40px;
        width: 120px;
        margin-top: 40px;
        background: linear-gradient(to right, rgb(147, 129, 227), rgb(59, 64, 203));
        color: white;
        font-size: 20px;
        font-weight: 520;
        border-color: rgba(252, 254, 255, 0);
        border-width: 0px;
        font-family: futura;
    }

    .purchase_container_sonic {
        display: none;
    }

    .rules_title {
        font-size: medium;
    }

    .rules_text {
        width: 300px;
        font-size: small;
    }

    .rules_key_mint {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        justify-items: center;
    }

    .rules_key {
        height: 200px;
        margin-right: 0px;
        margin-bottom: 10px;
    }

    .rules_nightly {
        margin-top: 5px;
        width: 300px;
    }

    .rules_milestone {
        height: 180px;
    }


    .rules_mint {
        height: 200px;
        margin-left: 0px;
        margin-top: 10px;
    }

    .rules_arrow {
        height: 10px;
        width: 20px;
        transform: rotate(90deg);
    }

    .rules_games {
        margin-top: 5px;
        display: flex;
        flex-direction: column;
    }

    .rules_game {
        margin-top: 5px;
        height: 200px;
    }

    .rules_refer {
        height: 120px;
    }

    .rules_wallet {
        width: 300px;
    }

    .table_title {
        font-size: small;
    }

    .table_text {
        font-size: small;
    }

    .table2 {
        margin-left: 0px;
    }

    .last_row {
        border-bottom: 0;
    }

    .p {
        font-size: xx-small;
    }

    .firstsentence {
        font-size: small;
    }

    .last_line {
        font-size: small;
    }

    .view_on_pc {
        display: block !important;
        color: rgb(255, 230, 0);
        font-family: futura;
        font-size: 17px;
        font-weight: 700;
        text-align: center;
        margin-top: 50px;
    }

    .media {
        display: flex;
        justify-content: center;
        margin-right: none;
        margin-left: 12px;
        margin-right: 0px;
        margin-top: 100px;

    }

    .med {
        margin-right: 0px;
    }

    .prize_pool_s {
        display: none;
    }

    .topperline {
        height: 20px;
        width: 390px;
        background-color: #3b3162;
        margin-top: 10px;
        display: flex;
        /* flex-direction: column; */
        font-size: small
    }

    .toppersubline {
        display: flex;
        flex-direction: column;
        font-weight: 500;
    }

    .play_content {
        width: 300px !important;

        /* margin: auto;
        display: flex;
        flex-direction: column;
        gap: 12px; */
    }

    .topper_season {
        font-size: medium;
        font-weight: 700;
        margin-left: 10px !important;
    }


    .seconds {
        width: 10px !important;
    }

    .countdown_num {
        width: 10px !important;
    }

    .mask {
        /* position: absolute;
        border-radius: 60px;
        top: 0;
        left: 0; */
        width: 300px !important;
        height: 300px !important;
        font-size: 15px !important;
        /* background-color: #b9b9b96f;
        z-index: 10;
        color: white;
        text-align: center;
        font-size: x-large;
        font-family: futura;
        font-weight: 600; */
    }

    .mask-l1 {
        margin-top: 30px !important;
    }

    .mask-bt {
        margin-top: 30px !important;
    }

    #board {
        margin-top: 60px;
        margin: auto;
        position: relative;
        background: rgb(23, 20, 60);
        padding: 6px !important;
        display: flex;
        flex-direction: column;
        gap: 2px !important;
        border-radius: 15px;
        /* box-shadow: 3px 3px 24px 3px rgb(21, 18, 45); */
        touch-action: none;
    }

    .tile,
    .tile-result {
        width: 70px !important;
        height: 70px !important;
        /* margin-top: 10px; */
        background-color: rgb(19, 16, 49);
        border-radius: 12px;
        display: flex;
        justify-content: end;
        align-items: end;
        /* text-align: center; */
    }

    .tile-wrapper {
        background-color: rgb(19, 16, 47);
        border-radius: 12px;
        width: 70px !important;
        height: 70px !important;
    }

    .submit_bt {
        width: 300px !important;
    }

    .score-box {
        width: 70px !important;
        height: 40px !important;
        margin-top: 20px !important;
    }

    .score-title{
        font-size:10px !important;
        gap: 1px  !important;
    }

    .instruction_mobile{
        display: none !important;
    }

    #score{
        font-size:10px !important;
    }

    #top-tile-num{
        font-size:10px !important;
    }

    .highlight{
        display: none !important;
    }
}


.copyright {
    margin-top: 15px;
    text-align: center;
    color: rgb(197, 197, 197);
    font-family: futura;
}



/*play part*/

/* .best_score{
    font-family: futura;
} */

.submit_2 {
    margin-top: 30px;
}

.submit_bt {
    width: 380px;
    display: block;
    margin: 0 auto;
    margin-top: 10px;

    font-size: large;
    font-weight: 600;
    border-radius: 10px;
    height: 40px;
    /* background-color: rgb(147, 129, 227); */
    background: linear-gradient(to right, rgb(147, 129, 227), rgb(59, 64, 203));
    color: white;
    border-color: rgba(255, 255, 255, 0);
    /* outline-style: solid; */
    outline-color: rgba(255, 255, 255);
    border-width: 0px;
    font-family: futura;
    cursor: pointer;
}

.keep_playing_bt {
    width: 150px;
}



.mute_img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
}

.mute_img:hover {
    transform: scale(0.9);
}

.sonic_play_xslogo {
    height: 32px;
    margin-left: 5px;
}

.loading {
    margin-top: 5px;
    margin-right: 20px;
}

.loading_content {
    display: flex;
    flex-direction: row;
}

.loading_text {
    font-family: futura;
    color: white;
    font-size: large;
    font-weight: 500;
}

.music_img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
}

.music_img:hover {
    transform: scale(0.9);
}

.game_instru {
    display: flex;
    align-items: center;
    justify-content: center;
}


.no_enough_key_error {
    display: flex;
    margin-top: 50px;
    justify-content: space-around;
}

.no_enough_sui_error {
    color: #ffffff;
    font-size: medium;
    text-align: center;
    font-weight: 700;
    font-family: futura;
    margin-top: 10px;
}

.no_enough_sui_error_line1 {
    color: #ffffff;
    font-size: large;
    text-align: center;
    font-weight: bold;
    font-family: futura;
}


.mint2048_1 {
    margin-top: 10px;
    font-size: x-large;
}

.mint2048_2 {
    margin-top: 10px;
}

.mint2048_3 {
    margin-top: 10px;
}

.mint2048_bt {
    display: flex;
    margin-top: 30px;
    justify-content: space-around;

}

.get_2048 {
    display: block;
    margin: 0 auto;
    margin-top: 30px;
}

.beatit_1 {
    margin-top: 30px;
}

.beatit_2 {
    margin-top: 10px;
}

.beatit_3 {
    margin-top: 20px;
}

.not_enough_score {
    width: 200px;
    display: block;
    margin: 0 auto;
    margin-top: 50px;
}

.purchase_key_first {
    margin-top: 20px;
}

.purchase_1stkey_bt {
    display: block;
    margin: 0 auto;
    width: 160px;
    margin-top: 30px;
}

.buy_more_keys {
    width: 160px;
}

.select_tm {
    margin-top: 20px;
}

.select_tm_bt {
    display: block;
    margin: 0 auto;
    margin-top: 40px;
}

.select_tm_bt:hover {
    background-color: rgb(132, 115, 203);
}

.no_enough_keys {
    margin-top: 20px;
}

.leaderboard_bt {
    width: 160px;
}

.mask {
    position: absolute;
    border-radius: 60px;
    top: 0;
    left: 0;
    width: 478px;
    height: 478px;
    background-color: #b9b9b96f;
    z-index: 10;
    color: white;
    text-align: center;
    font-size: x-large;
    font-family: futura;
    /* font-family: 'Courier New', Courier, monospace; */
    font-weight: 600;
}

.mask-l1 {
    margin-top: 90px;
    margin-left: 20px;
    margin-right: 20px;
}

.mask-l2 {
    margin-top: 30px;
    margin-left: 20px;
    margin-right: 20px;
}

.mask-l3 {
    margin-top: 30px;
    margin-left: 20px;
    margin-right: 20px;
}

.mask-bt {
    margin-top: 70px;
    border-radius: 100px;
    background: rgb(147, 126, 245);
    color: white;
    border-color: rgb(255, 255, 255);
    border-width: 0px;
    width: 200px;
    height: 40px;
    font-weight: bold;
    cursor: pointer;
    font-size: large;
    /* font-family: 'Courier New', Courier, monospace;  */
    font-family: futura;
}

.mask-bt:hover {
    background: rgb(118, 100, 194);
}

.play_container {
    background-color: rgb(8, 1, 43);
}

.palybody {
    background-image: url('../assets/images/game.png');
    background-repeat: no-repeat;
    /* background-size:100% 100%; */
    background-size: cover;
    background-color: rgb(8, 1, 43);
}

.play_content {
    width: 550px;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 12px;
    font-family: futura;
}

.topperline {
    height: 70px;
    background-color: #3b3162;
    margin-top: 10px;
    display: flex;
    align-items: center;
    color: white;
    /* justify-content: space-around; */
    font-family: futura;
    /* font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif; */
}

.topper_season {
    font-size: x-large;
    font-weight: bold;
    margin-left: 100px;
}

.toppersubline {
    display: flex;
    align-items: center;
    justify-content: space-around;
    align-items: center;
    font-weight: 500;
}

.prize_pool_s {
    margin-left: 80px;
}

.prize_pool_s_sonic {
    margin-left: 80px;
    color: #e5bd3b;
}

.countdown_s {
    margin-left: 150px;
    display: flex;
}

.countdown_s_sonic {
    margin-left: 100px;
    display: flex;
}

.tx_pp {
    text-shadow: 0 0 10px #b531bcea, 0 0 20px #b531bcea, 0 0 30px #b531bcea, 0 0 40px #b531bcea;
    font-family: futura;
    font-size: larger;
    font-weight: 700;
}

.countdown_title {
    margin-right: 20px;
}

.countdown_num {
    width: 100px;
    display: flex;
    text-shadow: 0 0 10px #b531bcea, 0 0 20px #b531bcea, 0 0 30px #b531bcea, 0 0 40px #b531bcea;
    font-family: futura;
    font-size: larger;
    font-weight: 700;
}

.last_minter {
    margin-left: 120px;
}

.btc_nft {
    margin-left: 120px;
}

.instructions {
    margin-top: 10px;
    text-align: center;
    /* padding: 20px 0; */
    font-weight: 500;
    color: white;
}

.topper {
    display: flex;
    justify-content: center;
}

.bitcoin {
    color: #e5bd3b;
}

#game-links-area>div {
    margin-bottom: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
}

.score-box {
    width: 132px;
    height: 50px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 3px;
    justify-content: center;
    align-items: center;
    background-color: rgb(147, 126, 245);
    font-size: large;
    font-weight: 500;
    position: relative;
    color: white;
    font-family: futura;
}

#score-diff {
    position: absolute;
    background-color: white;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    font-size: small;
}

.stationary {
    opacity: 0;
    top: 10px;
    transform: scale(0);
    left: 42px;
}

.score-title {
    font-size: medium;
    font-weight: 800;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
}

#score {
    color: white;
}

#best {
    color: white;
}


#board {
    margin-top: 60px;
    margin: auto;
    position: relative;
    background: rgb(23, 20, 60);
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 6px;
    border-radius: 15px;
    /* box-shadow: 3px 3px 24px 3px rgb(21, 18, 45); */
    touch-action: none;
}

#queue {
    position: absolute;
    top: 6px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    gap: 6px;
    z-index: 10;
}

#queue .queue-element {
    background-color: black;
    color: white;
    border: 1px solid #ccc;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    font-size: large;
    display: flex;
    justify-content: center;
    align-items: center;
}

.row {
    display: flex;
    flex-direction: row;
    gap: 6px;
}


.tile-wrapper {
    background-color: rgb(19, 16, 47);
    border-radius: 12px;
    width: 100px;
    height: 100px;
}

.tile,
.tile-result {
    width: 100px;
    height: 100px;
    /* margin-top: 10px; */
    background-color: rgb(19, 16, 49);
    border-radius: 12px;
    display: flex;
    justify-content: end;
    align-items: end;
    /* text-align: center; */
}


.number2 {
    font-size: small;
    margin-bottom: 2px;
    margin-right: 5px;
    /* font-weight: 500; */
    color: rgb(121, 121, 121);
    /* margin-top:90px; */

}

.number4 {
    font-size: small;
    margin-bottom: 2px;
    margin-right: 5px;
    color: rgb(81, 81, 81);
    /* font-weight: 500; */
    /* margin-top:90px; */

}

.number8 {
    font-size: small;
    margin-bottom: 2px;
    margin-right: 5px;
    /* font-weight: 500; */
    color: rgb(255, 255, 255);
    /* margin-top:90px; */

}

.number16 {
    font-size: small;
    margin-bottom: 2px;
    margin-right: 5px;
    /* font-weight: 500; */
    color: rgb(255, 255, 255);
    /* margin-top:90px; */

}

.number32 {
    font-size: small;
    margin-bottom: 2px;
    margin-right: 5px;
    /* font-weight: 500; */
    color: rgb(255, 255, 255);
    /* margin-top:90px; */

}

.number64 {
    font-size: small;
    margin-bottom: 2px;
    margin-right: 5px;
    /* font-weight: 500; */
    color: rgb(255, 255, 255);
    /* margin-top:90px; */

}

.number128 {
    font-size: small;
    margin-bottom: 2px;
    margin-right: 5px;
    /* font-weight: 500; */
    color: rgb(255, 255, 255);
    /* margin-top:90px; */

}

.number256 {
    font-size: small;
    margin-bottom: 2px;
    margin-right: 5px;
    /* font-weight: 500; */
    color: rgb(255, 255, 255);
    /* margin-top:90px; */

}

.number512 {
    font-size: small;
    margin-bottom: 2px;
    margin-right: 5px;
    /* font-weight: 500; */
    color: rgb(255, 255, 255);
    /* margin-top:90px; */

}

.number1024 {
    font-size: small;
    margin-bottom: 2px;
    margin-right: 5px;
    /* font-weight: 500; */
    color: rgb(255, 255, 255);
    /* margin-top:90px; */

}

.number2048 {
    font-size: small;
    margin-bottom: 2px;
    margin-right: 5px;
    /* font-weight: 500; */
    color: rgb(255, 255, 255);
    /* margin-top:90px; */

}

.number4096 {
    font-size: small;
    margin-bottom: 2px;
    margin-right: 5px;
    /* font-weight: 500; */
    color: rgb(255, 255, 255);
    /* margin-top:90px; */

}

.number8192 {
    font-size: small;
    margin-bottom: 2px;
    margin-right: 5px;
    /* font-weight: 500; */
    color: rgb(255, 255, 255);
    /* margin-top:90px; */

}

.number16384 {
    font-size: small;
    margin-bottom: 2px;
    margin-right: 5px;
    /* font-weight: 500; */
    color: rgb(255, 255, 255);
    /* margin-top:90px; */

}

.number32768 {
    font-size: small;
    margin-bottom: 2px;
    margin-right: 5px;
    /* font-weight: 500; */
    color: rgb(255, 255, 255);
    /* margin-top:90px; */

}


.signed-out .row-1 .tile {
    background-color: #49c5fa;
}

.signed-out .row-2 .tile {
    background-color: #4eb7f7;
}

.signed-out .row-3 .tile {
    background-color: #53a9f5;
}

.signed-out .row-4 .tile {
    background-color: #579af2;
}

.footer {
    text-align: center;
}

.footerwords {
    color: white;
}

.highlight {
    padding: 10px;
    background-color: #cff2f7;
    color: #28a2b4;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    /* width: 500px; */
}

.lastline {
    height: 10px;
}



.sonic_me2 {
    background-image: url('../assets/meme/me2.png');
    background-size: cover;
}

.sonic_me4 {
    background-image: url('../assets/meme/me4.png');
    background-size: cover;
}

.sonic_me8 {
    background-image: url('../assets/meme/me8.png');
    background-size: cover;
}

.sonic_me16 {
    background-image: url('../assets/meme/me16.png');
    background-size: cover;
}

.sonic_me32 {
    background-image: url('../assets/meme/me32.png');
    background-size: cover;
}

.sonic_me64 {
    background-image: url('../assets/meme/me64.png');
    background-size: cover;
}

.sonic_me128 {
    background-image: url('../assets/meme/me128.png');
    background-size: cover;
}

.sonic_me256 {
    background-image: url('../assets/meme/me256.png');
    background-size: cover;
}

.sonic_me512 {
    background-image: url('../assets/meme/me512.png');
    background-size: cover;
}

.sonic_me1024 {
    background-image: url('../assets/meme/me1024.png');
    background-size: cover;
}

.sonic_me2048 {
    background-image: url('../assets/meme/me2048.png');
    background-size: cover;
}

.sonic_me4096 {
    background-image: url('../assets/meme/me4096.png');
    background-size: cover;
}

.sonic_me8192 {
    background-image: url('../assets/meme/me8192.png');
    background-size: cover;
}

.sonic_me16384 {
    background-image: url('../assets/meme/me16384.png');
    background-size: cover;
}

.sonic_me32768 {
    background-image: url('../assets/meme/me32768.png');
    background-size: cover;
}



.sonic_chain2 {
    background-image: url('../assets/chain/chn2.png');
    background-size: cover;
}

.sonic_chain4 {
    background-image: url('../assets/chain/chn4.png');
    background-size: cover;
}

.sonic_chain8 {
    background-image: url('../assets/chain/chn8.png');
    background-size: cover;
}

.sonic_chain16 {
    background-image: url('../assets/chain/chn16.png');
    background-size: cover;
}

.sonic_chain32 {
    background-image: url('../assets/chain/chn32.png');
    background-size: cover;
}

.sonic_chain64 {
    background-image: url('../assets/chain/chn64.png');
    background-size: cover;
}

.sonic_chain128 {
    background-image: url('../assets/chain/chn128.png');
    background-size: cover;
}

.sonic_chain256 {
    background-image: url('../assets/chain/chn256.png');
    background-size: cover;
}

.sonic_chain512 {
    background-image: url('../assets/chain/chn512.png');
    background-size: cover;
}

.sonic_chain1024 {
    background-image: url('../assets/chain/chn1024.png');
    background-size: cover;
}

.sonic_chain2048 {
    background-image: url('../assets/chain/chn2048.png');
    background-size: cover;
}

.sonic_chain4096 {
    background-image: url('../assets/chain/chn4096.png');
    background-size: cover;
}

.sonic_chain8192 {
    background-image: url('../assets/chain/chn8192.png');
    background-size: cover;
}

.sonic_chain16384 {
    background-image: url('../assets/chain/chn16384.png');
    background-size: cover;
}

.sonic_chain32768 {
    background-image: url('../assets/chain/chn32768.png');
    background-size: cover;
}


.me1 {
    background-image: url('../assets/meme/me2.png');
    background-size: cover;
}

.me2 {
    background-image: url('../assets/meme/me4.png');
    background-size: cover;
}

.me3 {
    background-image: url('../assets/meme/me8.png');
    background-size: cover;
}

.me4 {
    background-image: url('../assets/meme/me16.png');
    background-size: cover;
}

.me5 {
    background-image: url('../assets/meme/me32.png');
    background-size: cover;
}

.me6 {
    background-image: url('../assets/meme/me64.png');
    background-size: cover;
}

.me7 {
    background-image: url('../assets/meme/me128.png');
    background-size: cover;
}

.me8 {
    background-image: url('../assets/meme/me256.png');
    background-size: cover;
}

.me9 {
    background-image: url('../assets/meme/me512.png');
    background-size: cover;
}

.me10 {
    background-image: url('../assets/meme/me1024.png');
    background-size: cover;
}

.me11 {
    background-image: url('../assets/meme/me2048.png');
    background-size: cover;
}

.me12 {
    background-image: url('../assets/meme/me4096.png');
    background-size: cover;
}

.me13 {
    background-image: url('../assets/meme/me8192.png');
    background-size: cover;
}

.me14 {
    background-image: url('../assets/meme/me16384.png');
    background-size: cover;
}

.me15 {
    background-image: url('../assets/meme/me32768.png');
    background-size: cover;
}

.chain1 {
    background-image: url('../assets/chain/chn2.png');
    background-size: cover;
}

.chain2 {
    background-image: url('../assets/chain/chn4.png');
    background-size: cover;
}

.chain3 {
    background-image: url('../assets/chain/chn8.png');
    background-size: cover;
}

.chain4 {
    background-image: url('../assets/chain/chn16.png');
    background-size: cover;
}

.chain5 {
    background-image: url('../assets/chain/chn32.png');
    background-size: cover;
}

.chain6 {
    background-image: url('../assets/chain/chn64.png');
    background-size: cover;
}

.chain7 {
    background-image: url('../assets/chain/chn128.png');
    background-size: cover;
}

.chain8 {
    background-image: url('../assets/chain/chn256.png');
    background-size: cover;
}

.chain9 {
    background-image: url('../assets/chain/chn512.png');
    background-size: cover;
}

.chain10 {
    background-image: url('../assets/chain/chn1024.png');
    background-size: cover;
}

.chain11 {
    background-image: url('../assets/chain/chn2048.png');
    background-size: cover;
}

.chain12 {
    background-image: url('../assets/chain/chn4096.png');
    background-size: cover;
}

.chain13 {
    background-image: url('../assets/chain/chn8192.png');
    background-size: cover;
}

.chain14 {
    background-image: url('../assets/chain/chn16384.png');
    background-size: cover;
}

.chain15 {
    background-image: url('../assets/chain/chn32768.png');
    background-size: cover;
}

.m1,
.m2,
.m3,
.m4,
.m5,
.m6,
.m7,
.m8,
.m9,
.m10,
.m11,
.m12,
.m13,
.m14,
.m15,
.ch1,
.ch2,
.ch3,
.ch4,
.ch5,
.ch6,
.ch7,
.ch8,
.ch9,
.ch10,
.ch11,
.ch12,
.ch13,
.ch14,
.ch15 {
    height: 70px;
    width: 70px;
    margin-top: 10px;
    margin-left: 5px;
}


.m1 {
    background-image: url('../assets/meme/me2.png');
    background-size: cover;
}

.m2 {
    background-image: url('../assets/meme/me4.png');
    background-size: cover;
}

.m3 {
    background-image: url('../assets/meme/me8.png');
    background-size: cover;
}

.m4 {
    background-image: url('../assets/meme/me16.png');
    background-size: cover;
}

.m5 {
    background-image: url('../assets/meme/me32.png');
    background-size: cover;
}

.m6 {
    background-image: url('../assets/meme/me64.png');
    background-size: cover;
}

.m7 {
    background-image: url('../assets/meme/me128.png');
    background-size: cover;
}

.m8 {
    background-image: url('../assets/meme/me256.png');
    background-size: cover;
}

.m9 {
    background-image: url('../assets/meme/me512.png');
    background-size: cover;
}

.m10 {
    background-image: url('../assets/meme/me1024.png');
    background-size: cover;
}

.m11 {
    background-image: url('../assets/meme/me2048.png');
    background-size: cover;
}

.m12 {
    background-image: url('../assets/meme/me4096.png');
    background-size: cover;
}

.m13 {
    background-image: url('../assets/meme/me8192.png');
    background-size: cover;
}

.m14 {
    background-image: url('../assets/meme/me16384.png');
    background-size: cover;
}

.m15 {
    background-image: url('../assets/meme/me32768.png');
    background-size: cover;
}

.ch1 {
    background-image: url('../assets/chain/chn2.png');
    background-size: cover;
}

.ch2 {
    background-image: url('../assets/chain/chn4.png');
    background-size: cover;
}

.ch3 {
    background-image: url('../assets/chain/chn8.png');
    background-size: cover;
}

.ch4 {
    background-image: url('../assets/chain/chn16.png');
    background-size: cover;
}

.ch5 {
    background-image: url('../assets/chain/chn32.png');
    background-size: cover;
}

.ch6 {
    background-image: url('../assets/chain/chn64.png');
    background-size: cover;
}

.ch7 {
    background-image: url('../assets/chain/chn128.png');
    background-size: cover;
}

.ch8 {
    background-image: url('../assets/chain/chn256.png');
    background-size: cover;
}

.ch9 {
    background-image: url('../assets/chain/chn512.png');
    background-size: cover;
}

.ch10 {
    background-image: url('../assets/chain/chn1024.png');
    background-size: cover;
}

.ch11 {
    background-image: url('../assets/chain/chn2048.png');
    background-size: cover;
}

.ch12 {
    background-image: url('../assets/chain/chn4096.png');
    background-size: cover;
}

.ch13 {
    background-image: url('../assets/chain/chn8192.png');
    background-size: cover;
}

.ch14 {
    background-image: url('../assets/chain/chn16384.png');
    background-size: cover;
}

.ch15 {
    background-image: url('../assets/chain/chn32768.png');
    background-size: cover;
}


.mm1,
.mm2,
.mm3,
.mm4,
.mm5,
.mm6,
.mm7,
.mm8,
.mm9,
.mm10,
.mm11,
.mm12,
.mm13,
.mm14,
.mm15,
.cc1,
.cc2,
.cc3,
.cc4,
.cc5,
.cc6,
.cc7,
.cc8,
.cc9,
.cc10,
.cc11,
.cc12,
.cc13,
.cc14,
.cc15 {
    height: 50px;
    width: 50px;
    /* margin-top:10px; */
    margin-left: 10px;

}


.mm1 {
    background-image: url('../assets/meme/me2.png');
    background-size: cover;
}

.mm2 {
    background-image: url('../assets/meme/me4.png');
    background-size: cover;
}

.mm3 {
    background-image: url('../assets/meme/me8.png');
    background-size: cover;
}

.mm4 {
    background-image: url('../assets/meme/me16.png');
    background-size: cover;
}

.mm5 {
    background-image: url('../assets/meme/me32.png');
    background-size: cover;
}

.mm6 {
    background-image: url('../assets/meme/me64.png');
    background-size: cover;
}

.mm7 {
    background-image: url('../assets/meme/me128.png');
    background-size: cover;
}

.mm8 {
    background-image: url('../assets/meme/me256.png');
    background-size: cover;
}

.mm9 {
    background-image: url('../assets/meme/me512.png');
    background-size: cover;
}

.mm10 {
    background-image: url('../assets/meme/me1024.png');
    background-size: cover;
}

.mm11 {
    background-image: url('../assets/meme/me2048.png');
    background-size: cover;
}

.mm12 {
    background-image: url('../assets/meme/me4096.png');
    background-size: cover;
}

.mm13 {
    background-image: url('../assets/meme/me8192.png');
    background-size: cover;
}

.mm14 {
    background-image: url('../assets/meme/me16384.png');
    background-size: cover;
}

.mm15 {
    background-image: url('../assets/meme/me32768.png');
    background-size: cover;
}

.cc1 {
    background-image: url('../assets/chain/chn2.png');
    background-size: cover;
}

.cc2 {
    background-image: url('../assets/chain/chn4.png');
    background-size: cover;
}

.cc3 {
    background-image: url('../assets/chain/chn8.png');
    background-size: cover;
}

.cc4 {
    background-image: url('../assets/chain/chn16.png');
    background-size: cover;
}

.cc5 {
    background-image: url('../assets/chain/chn32.png');
    background-size: cover;
}

.cc6 {
    background-image: url('../assets/chain/chn64.png');
    background-size: cover;
}

.cc7 {
    background-image: url('../assets/chain/chn128.png');
    background-size: cover;
}

.cc8 {
    background-image: url('../assets/chain/chn256.png');
    background-size: cover;
}

.cc9 {
    background-image: url('../assets/chain/chn512.png');
    background-size: cover;
}

.cc10 {
    background-image: url('../assets/chain/chn1024.png');
    background-size: cover;
}

.cc11 {
    background-image: url('../assets/chain/chn2048.png');
    background-size: cover;
}

.cc12 {
    background-image: url('../assets/chain/chn4096.png');
    background-size: cover;
}

.cc13 {
    background-image: url('../assets/chain/chn8192.png');
    background-size: cover;
}

.cc14 {
    background-image: url('../assets/chain/chn16384.png');
    background-size: cover;
}


.cc15 {
    background-image: url('../assets/chain/chn32768.png');
    background-size: cover;
}



.create_bt {
    display: block;
    margin: 0 auto;
    margin-top: 30px;
}

.merge {
    animation-name: merge;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
    animation-direction: alternate;
}

.appear {
    /* opacity: 0; */
    animation-name: appear;
    animation-duration: 0.3s;
    /* animation-iteration-count: 1;
    animation-direction: alternate;
    animation-fill-mode: forwards; */
}


.left1,
.left2,
.left3,
.right1,
.right2,
.right3,
.up1,
.up2,
.up3,
.down1,
.down2,
.down3 {
    animation-duration: 0.15s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    position: relative;
}

.left1 {
    animation-name: left1;
}

.left2 {
    animation-name: left2;
}

.left3 {
    animation-name: left3;
}

.right1 {
    animation-name: right1;
}

.right2 {
    animation-name: right2;
}

.right3 {
    animation-name: right3;
}

.up1 {
    animation-name: up1;
}

.up2 {
    animation-name: up2;
}

.up3 {
    animation-name: up3;
}

.down1 {
    animation-name: down1;
}

.down2 {
    animation-name: down2;
}

.down3 {
    animation-name: down3;
}


@keyframes left1 {
    to {
        margin-left: -106px;
    }
}

@keyframes left2 {
    to {
        margin-left: -212px;
    }
}

@keyframes left3 {
    to {
        margin-left: -318px;
    }
}

@keyframes right1 {
    to {
        margin-left: 106px;
    }
}

@keyframes right2 {
    to {
        margin-left: 212px;
    }
}

@keyframes right3 {
    to {
        margin-left: 318px;
    }
}

@keyframes up1 {
    to {
        margin-top: -106px;
    }
}

@keyframes up2 {
    to {
        margin-top: -212px;
    }
}

@keyframes up3 {
    to {
        margin-top: -318px;
    }
}

@keyframes down1 {
    to {
        margin-top: 106px;
    }
}

@keyframes down2 {
    to {
        margin-top: 212px;
    }
}

@keyframes down3 {
    to {
        margin-top: 318px;
    }
}

@keyframes merge {
    40% {
        width: 110px;
        height: 110px;
        margin: -5px;
    }

    75% {
        width: 80px;
        height: 80px;
        margin: 10px;
    }

    100% {
        width: 100px;
        height: 100px;
        margin: 0;
    }
}

@keyframes appear {
    0% {
        width: 1px;
        height: 1px;
        margin: 50px;
        opacity: 0;
    }

    100% {
        width: 100px;
        height: 100px;
        margin: 0;
        opacity: 1;
    }
}


/* game over */
.game_over {
    display: flex;
    flex-direction: column;
    color: white;
    margin-top: 20px;
}

.game_over_status {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.game_over_title {
    text-align: center;
    font-size: x-large;
    font-weight: bold;
    text-shadow: 0 0 10px #6495ed, 0 0 10px cornflowerblue, 0 0 10px cornflowerblue, 0 0 10px cornflowerblue;
}

.game_over_score {
    margin-right: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.game_over_tile {
    margin-left: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.game_over_subtitle {
    font-weight: bold;
    text-shadow: 0 0 10px #6495ed, 0 0 10px cornflowerblue, 0 0 10px cornflowerblue, 0 0 10px cornflowerblue;
}

/* .tile-result{
    margin-top: 10px;
} */

.score_color {
    background-color: rgb(88, 105, 243);
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: large;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.tile-result-box {
    margin-top: 10px;
}

.hidden {
    display: none;
}


/* LeaderBoard Part */
.my_game_sonic {
    display: flex;

}

.leaderboard_isended {}

.fist_game_bt {
    width: 120px;
    display: block;
    margin: 0 auto;
    margin-top: 50px;

    font-size: medium;
    font-weight: 600;
    border-radius: 100px;
    height: 40px;
    /* background-color: rgb(147, 129, 227); */
    background: linear-gradient(to right, rgb(147, 129, 227), rgb(59, 64, 203));
    color: white;
    border-color: rgba(255, 255, 255, 0);
    /* outline-style: solid; */
    outline-color: rgba(255, 255, 255);
    border-width: 0px;
    font-family: futura;
    cursor: pointer;
}


.leaderboard_container {
    background-color: rgb(8, 1, 43);
}

.leaderboard_body {
    background-image: url('../assets/images/leaderboard_bg.png');
    background-repeat: no-repeat;
    /* background-size:100% 100%; */
    background-size: cover;
    background-color: rgb(8, 1, 43);
}

.leaderboard_content {
    display: flex;
    margin-top: 50px;
    justify-content: center;
    margin-bottom: 30px;
    color: white;
}

.leaderboard_left {
    margin-right: 30px;
    width: 30%;
    height: 670px;
    background-color: #0f0a3ab4;
    /* font-family: Georgia, 'Times New Roman', Times, serif; */
    font-family: futura;
    font-weight: 500;
    box-shadow: 0 0 5px #615f70b4, 0 0 10px #615f70b4;
}

.leaderboard_my_game_display {
    font-family: futura;
    color: white;
    cursor: pointer;
    display: flex;
    flex-direction: column;
}

.leaderboard_my_game_action {
    font-size: larger;
    margin-left: 90px;
    font-weight: bold;
}

.leaderboard_index {
    width: 20px;
}

.quest_idx{
    width: 30px;
    text-align: center;
}

.leaderboard_team_img {
    width: 35px;
    margin-left: 8px;
}

.leaderboard_my_score {
    margin-left: 90px;
    display: flex;
    flex-direction: row;
}

.leaderboard_toptile {
    margin-left: 90px;
    font-size: small;
    display: flex;
    width: 100px;
}



.leaderboard_status {
    margin-left: 15px;
    display: flex;
    flex-direction: row;
}

.leaderboard_score {
    margin-left: 12px;
    display: flex;
    flex-direction: row;
}

.leaderboard_my_score_num {
    margin-left: 2px;
}

.leaderboard_address {
    margin-left: 12px;
}



.leaderboard_middle {
    width: 38%;
    /* width:65%; */
    height: 670px;
    /* height:900px; */
    margin-left: 20px;
    margin-right: 20px;
    background-color: #0f0a3ab4;
    /* font-family: Georgia, 'Times New Roman', Times, serif; */
    font-family: futura;
    font-weight: 500;
    box-shadow: 0 0 5px #615f70b4, 0 0 10px #615f70b4;
    /* box-shadow:  0 0 10px #615f70b4, 0 0 10px #615f70b4, 0 0 10px #615f70b4, 0 0 10px #615f70b4;; */
}

.leaderboard_display {
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: center; */
    font-size: 15px;
    font-weight: 700;
    font-family: futura;
}

.leaderboard_display_temp{
    color: rgb(197, 228, 255);
    display: block;
    margin: 0 auto;
    /* text-align: center; */
    /* flex-direction: column; */
    /* align-items: center; */
    /* justify-content: center; */
    font-size: 15px;
    font-weight: 700;
    font-family: futura;
}

.quest_display {
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: center; */
    font-size: 15px;
    font-weight: 700;
    font-family: futura;
    height:40px;
}

.medals{
    height: 40px;
    /* width: 20px; */
}



.points{
    height:20px;
    margin-left: 20px;
    margin-right: 10px;

}

.yellow {
    color: yellow
}

.leaderboard_quest_bot{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.leaderboard_name{
    width: 120px;
    margin-left: 20px;
}

.leaderboard_all {
    display: flex;
    /* justify-content: center; */
}



.leaderboard_score_status {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.leaderboard_right {
    margin-left: 30px;
    width: 40%;
    height: 670px;
    background-color: #0f0a3ab4;
    /* font-family: Georgia, 'Times New Roman', Times, serif; */
    font-family: futura;
    font-weight: 500;
    box-shadow: 0 0 5px #615f70b4, 0 0 10px #615f70b4;
}

.leaderboard_title {
    text-align: center;
    margin-top: 30px;
    font-size: larger;
    font-weight: bold;
}

.leaderboard_last_line {
    text-align: center;
    margin-top: 15px;
    font-family: futura;
}

.btc_tile {
    height: 80px;
    display: block;
    margin: auto;
    margin-top: 20px;
}

.btc_tile_num {
    font-family: futura;
    text-align: center;
    margin-top: 20px;
    font-size: large;
}


.leaderboard_title_2 {
    text-align: center;
    margin-top: 50px;
    font-size: larger;
    font-weight: bold;
}

.leaderboard_title_3 {
    text-align: center;
    margin-top: 20px;
    font-size: medium;
}

.lb {
    display: block;
    margin: auto;
    height: 200px;
    margin-top: 50px;
}

.lb_soon {
    font-family: futura;
    text-align: center;
    margin-top: 20px;
}
